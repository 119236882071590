import React from 'react'
import Page from './../Page'

function Updates(props) {
  return (
    <Page title="Updates" footer>
      <div className="Div Div-1">
        ***Please note that per Duke regulations and requirements by the state of North Carolina and Durham county, masks will be <b>REQUIRED</b> throughout both the ceremony and reception regardless of vaccination status***
      </div>
      <div className="Div Div-2">
        We are most appreciative of our friends and family during this time. We are so fortunate to have such supportive and loving people surrounding us as we re-plan our wedding day.
      </div>
      <div className="Div Div-2">
        As we proceed with planning, we will continue to monitor the current state of the COVID-19 pandemic. We will ensure our wedding implements restrictions that are both required and recommended in order to keep all our guests safe.
      </div>
      <div className="Div Div-3">
        We hope that all our guests will be vaccinated, and this pandemic will be behind us in time for our wedding. However, we strongly encourage anyone experiencing any symptoms related to COVID-19 or any illness to celebrate with us from afar.
      </div>
      <div className="Div Div-4">
        We highly encourage our guests to receive a COVID-19 vaccine. Please reach out to us, or click <a href="">here</a> from more information.
      </div>

      <div className="Div Div-5">
        Masks will be welcomed if they are not<br/> required.
      </div>
      <img className="Img-1" src={`${process.env.PUBLIC_URL}/updates-heart.png`}/>
    </Page>
  )
}

export default Updates;
