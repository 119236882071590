import React from 'react'
import Nav from './Nav';
import './Page.scss';

function Page({children, nav, title, footer}) {
  let classes = [
    'Page',
    nav ? 'Page-WithNav' : '',
    `Page-${title.replace(/ /g, '-')}`
  ].join(' ')

  return (
    <div className={classes}>
      <div className='BannerImage'>
        <img src={`${process.env.PUBLIC_URL}/banner-image.png`}/>
        <div className='BannerImage-Title'> {title}</div>
      </div>
      <Nav/>
      {children}

      {footer ?
        <div className='FooterImage'>
          <img src={`${process.env.PUBLIC_URL}/guptagetsrich.png`}/>
        </div> : null
      }
    </div>
  )
}
export default Page
