import React from 'react'
import Page from './../Page'

function Registry(props) {
  return (
    <Page title="Registry" footer>
      <div className="Registry">
        <div className="R R1">
          <a className="Amazon" href="https://www.amazon.com/wedding/registry/2MM9TMQUAWA3X?ref=wr_search_page_result_1" target="_blank">Amazon</a>
          <a className="CB" href="https://www.crateandbarrel.com/gift-registry/kara-richey-and-shubham-gupta/r6085788" target="_blank">Crate & Barrel</a>
        </div>
        <img className="Flower" src={`${process.env.PUBLIC_URL}/flower.png`}/>
        <div className="R R2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/registry.png)`}}>
        </div>
      </div>
    </Page>
  )
}

export default Registry;
