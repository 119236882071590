import React from 'react'
import Page from './../Page'
import Person from './Person'

function Home(props) {
  return (
    <Page title="Meet the Wedding Party" footer>
      <div className="People">
        <Person
          image={`${process.env.PUBLIC_URL}/people/ashley.png`}
          name="Ashley Whitfield"
          title="Maid of Honor"
          description="Ashley and Kara met their freshman year at the University of North Carolina Wilmington. They spent two years as roommates, and have remained best friends since. The iconic era, the Freshman Year of Life, occurred during weekly drives from Charlotte to Raleigh and the adventures that ensued. Ashley, her fiancé Brandon, Kara, and Shubham travel together often. The only spring vacation they missed was due to lockdown from COVID-19. Ashley is a cardiac nurse at Novant Health in Charlotte, NC. She and Brandon have an adorable diva dog named Zita!"
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/maggie.png`}
          name="Maggie McLaughlin"
          title="Maid of Honor"
          description="Maggie and Kara have been best friends since the first day of ninth grade, where they sat across from each other in art class. The duo is known for wearing snowsuits in warm weather, watching The Grinch on repeat, passing notes, spending the entirety of their free time with animals, laughing too much and too often, and being each others’ number one fans at all times. While they only see each other occasionally, a day without the two talking is rare. Maggie lives in Cary, North Carolina. She is a physician assistant in urgent care, and spends her free time with her lovable pup, Bear. "
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/amandag.png`}
          name="Amanda Greth"
          title="Bridesmaid"
          description="Amanda and Kara met in Costa Rica in June of 2016. They both stepped out of a plane knowing no one, and left after a month with a lifelong friendship, yoga teacher certifications, and many stories to tell (if you haven’t already heard, ask us about the river). Living across the country from each other has not stopped the two from continuing their soul sisterhood. Amanda is currently studying acupuncture and Chinese medicine at Yo San University in Los Angeles, CA. She has the sweetest rescue pup named Anji (“blessing”), and two jungle cats named Niko and Thor."
        />


        <Person
          image={`${process.env.PUBLIC_URL}/people/amandar.png`}
          name="Amanda Richey"
          title="Bridesmaid"
          description="Amanda is the best sister-in-law there ever was. She and Davey, Kara’s brother, met their junior year at North Carolina State University. They were married in May 2018. Since Amanda and Kara met, they have had so many adventures together. They are known for matching unintentionally and spending all the family time chatting together. Amanda works for Brentwood Elementary as a S.T.E.M teacher. She and Davey live in Raleigh, NC with the best ever furry nephew and snapdragon, Colt baby!"
        />


        <Person
          image={`${process.env.PUBLIC_URL}/people/arielle.png`}
          name="Arielle Beaman"
          title="Bridesmaid"
          description="Arielle and Kara met while they were both working at Markey Cancer Center in Lexington, KY. They discovered a mutual love for hiking, kayaking, coffee shops, travel, brunch, sushi, breweries, pups, and street fairs. Naturally, they spend their free time doing these things together. While living far away from each other, they keep in touch via simultaneously Kara’s most hilarious and most supportive text message thread, which has been known to occur at odd hours of the morning. Arielle lives in Lexington, KY with her new husband Seth, their giant-hearted and big-pawed Angie, the kitties Jezzy and Coco. She is an echocardiographer in Louisville, Kentucky."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/martha.png`}
          name="Martha Alejandro-Bird"
          title="Bridesmaid"
          description="Marty and Kara met in their second semester of graduate school, in Dr. Delgado’s clinical group for health assessment. Together, they prepared for intense exams and practicums, though after each On-Campus Intensive the group made sure to eat at one of the foodie favorites in Durham. They even attended a Duke basketball game in Cameron Indoor together! Marty is a gastroenterology nurse practitioner, and will not shy away from sending us laxative tea and poo-pourri for Christmas, complete with a poem she wrote herself. She lives in Williamsburg, VA, though has lived all over the United States. Marty and her husband have two beautiful children and the most lovable big pup, Jed. "
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/lauren.png`}
          name="Lauren Grainda"
          title="Bridesmaid"
          description="Lauren is Kara’s younger cousin. In their childhood days, they were known for obstacle course-making, playing or watching Rugrats on Nickelodeon, and probably making messes but acting like scientists somewhere. Surprising that they’re both in healthcare now? Lauren is a doctor of physical therapy at TherAplay, specializing in pediatrics and hippotherapy in Indianapolis, IN. She and her husband Mark married in October 2019. They have a yellow Labrador retriever named Kala. The two of them, Kara, and Shubham are on a mission to try all the bourbon in all the land. Lauren has helped Kara with all aspects of wedding planning, everything from tiny details to planning her bachelorette party. We aren’t quite sure what would happen without her help and we are so so thankful!"
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/steph.png`}
          name="Stephanie McMillan"
          title="Bridesmaid"
          description="Stephanie is Kara’s older cousin, though the two often get mistaken as twins or sisters. Growing up, they were known for making ridiculous AIM screen names to scare away boys (glad Shooby wasn’t around for that era!), jumping on trampolines in bathing suits, playing snowboarding video games, and burning CDs. She and Kara both went into the nursing field, and Stephanie helped inspire Kara’s travel nurse adventures. Stephanie is a float pool nurse in Phoenix. She is also a travel nurse and wellness coach, and has been helping Kara crush her goals since just before the pandemic. She has a sweet doggo named Ralo. Fun fact: she kicks some booty at bodybuilding competitions!"
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/chelsea.png`}
          name="Chelsea Alexander"
          title="Chapel Greeter"
          description="Chelsea is Kara’s cousin on her mom’s side. When they were little, they were grouped together as the “allergy children” due to their shared experiences of anaphylaxis at a young age. Nevertheless, they still thrived on graham cracker pie crusts with whipped cream, and, well.. Mimi’s stuffing, at most family gatherings. As kids, they loved to play with marbles on their grandparents’ shag carpet. Much to Papa’s dismay, they never failed to leave a few behind after cleaning up. They bonded over their mutual love of dolls, performing arts, and pretty much anything girly. Chelsea is a software developer by day and a figure skater extraordinaire by night. She and Katie moved to North Carolina from California in 2018. "
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/katie.png`}
          name="Katie Alexander"
          title="Chapel Greeter"
          description="Katie is Kara’s cousin on her mom’s side. She and Chelsea moved to North Carolina just in time for Kara to be back there for graduate school, in a hurricane no less. They learned about battery packs and the southern ways of buying the stores out of milk and bread during their first few days as southerners. Now, Kara and Katie spend time together walking their pups and bonding over the wonders of adulthood. Growing up, Kara, Chelsea, and Katie were always in matching dresses their Mimi made with her magical sewing machine. They learned how to sew together, something that has been useful all their lives. Katie is a speech-language pathologist working with kiddos in the triangle area. She has a very sweet and comical good boi named Ace."
        />

        <div class="">
        </div>

        <Person
          image={`${process.env.PUBLIC_URL}/people/anjan.png`}
          name="Anjan Talukdar"
          title="Best Man"
          description="Shubham and Anjan met 20 years ago in their first year of medical school, while enduring hazing together. That bond, built in despair and tragedy, has only strengthened over time. They came to the US together and pursued their respective residencies. They have been there for each other through all of life’s ups and downs, and share everything, from work complications to Orangetheory workout summaries. Anjan in a vascular surgeon in Omaha, NE, and lives there with his wife, Andrea, his daughter, Sophia, and their two dogs, Sadie and Willow. They are expecting another baby in the spring!"
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/ajaypal.png`}
          name="Ajaypal Singh"
          title="Groomsman"
          description="Ajaypal and Shubham have been friends since medical school. They share a passion for discussing politics, judging people, eating at the coolest new restaurants, and drinking good alcohol. Shubham and Kara visit them at least yearly, usually to spend New Years in Chicago. He and his wife, Shilpa, live in Chicago, IL with their two sons, two sons,Ruhaan and Ridhaan! Ajaypal is an interventional gastroenterologist at Rush University."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/ali.png`}
          name="Ali Ziada"
          title="Groomsman"
          description="Ali is a pediatric urologist at the University of Kentucky. He was Shubham’s immediate mentor and colleague when Shubham joined UK straight out of his fellowship at Duke. He is Shubham’s daily coffee partner, operating room buddy, good food-giver, designated driver, equally bad at golf pal. The two share a love of sarcasm, and are often heard laughing and having too much fun. Ali lives in Lexington, KY with his wife, Amira, his son, Adam, and his dog, Cody."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/amul.png`}
          name="Amul Bhalodi"
          title="Groomsman"
          description="Amul joined practice at the University of Kentucky two years after Shubham, after completing his minimally invasive urology fellowship there. He and Shubham became friends and confidants through a shared love for bourbon and other nefarious activities. Amul kept busy in other spirits too, spawning two beautiful boys in the span of two years with his badass wife, Staci. He lives in Lexington with Staci, Tino (Austin), Tony (Anthony), and their dog Buddy."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/ankit.png`}
          name="Ankit Gupta"
          title="Groomsman"
          description="Ankit is Shubham’s friend from medical school (see a pattern here? Medical school bonds people). He enjoys laying around and not doing anything else. In his free time, he practices pulmonary critical care in Hartford, CT. He is a fan of traveling, good food, and gossip. He believes he is one of the best cricket players and athletes around, a claim that many of his friends dispute. His wife, Priya, is truly his better half. Shubham is looking forward to having that in common with him."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/brad.png`}
          name="Bradley Potts"
          title="Groomsman"
          description="Bradley and Shubham met while Shubham was a fellow and Bradley a medical student at Duke. They struck an immediate friendship over debauchery and off-color humor. The pair has remained in touch through meetings and the Kentucky Derby. Bradley is a senior resident in urology at Duke and resides in Durham, NC. He is an officer and a gentleman."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/david.png`}
          name="David Richey"
          title="Groomsman"
          description="David is Kara’s younger brother, and the human in the picture above. He and Shubham met for the first time at a haunted house. They both put on brave faces while they held giant snakes, battled clowns, escaped small spaces, and saved the world from the next plague. They get together for all the major holidays. Shubham makes the guac and the drinks. Davey eats the guac and drinks the drinks. They share a love for beer and bourbon. Shubham says he outdrank Davey at his bachelor party, so he wants to see what Davey can do at his. He and his puppy Colt live with his beautiful wife, Amanda, in Raleigh, NC."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/sumit.png`}
          name="Sumit Isharwal"
          title="Groomsman"
          description="Shubham and Sumit have shared an unbreakable bond since their medical school days, when Shubham hazed Sumit in a dimly-lit room for several hours without mercy. Since that time, they’ve participated in alcohol-soused parties, grand plans which have never been realized, and passing judgement on society. He is a slacker when it comes to outdoor activities (he once sprained his ankle while standing still). Sumit is a urologic oncologist at the University of Virginia."
        />

        <Person
          image={`${process.env.PUBLIC_URL}/people/matt.png`}
          name="Matthew Evans"
          title="Chapel Greeter"
          description="Matt and Kara met at Duke University while they studied to be nurse practitioners. Matt called Kara out on a mistake she made, and was surprised when she reached out to him to thank him. They found out they were both major Swifties, and the two have been inseparable since. Now, days feel incomplete without an hours-long phone conversation. Matt is the lovable, outgoing, fun one, and Kara is the one who reads each textbook chapter four times. Matt is a nurse practitioner in Greeneville, SC at CVS Minute Clinic, and is almost finished getting his doctorate of nursing practice. He has a Siberian Husky baby named Jaxon."
        />
      </div>
    </Page>
  )
}
export default Home
