import React from 'react'
import Page from './../Page'

function OurWedding(props) {
  return (
    <Page title="Our Wedding" footer>
      <img className="OurWedding-Img Img-1" src={`${process.env.PUBLIC_URL}/wedding-ceremony.png`}/>
      <img className="OurWedding-Img Img-2" src={`${process.env.PUBLIC_URL}/cocktail-hour.png`}/>
    </Page>
  )
}
export default OurWedding
