import React from 'react'
import Page from './../Page'

function Home(props) {
  const weddingDate = new Date("Sep 4, 2021 14:00:00").getTime();

  const now = new Date().getTime();
  const distance = weddingDate - now;
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  return (
    <Page title="Home" footer>
      <div className="LiveStream">
        <img alt="" src={`${process.env.PUBLIC_URL}/livestream-image.png`}/>
        <div className="Links">
          <a href="https://youtu.be/0X00rtixUSY" className="Wedding" target="_blank" rel="noreferrer">
            <div className="Title">CLICK HERE TO WATCH OUR WEDDING LIVE!</div>
            <div className="Time">WEBCAST STARTS AT 5:30 PM EST</div>
          </a>
          <a href="https://uhhospitals.zoom.us/j/92103370807?pwd=dXNpdmZGUWhXV0pFM09JY0hZUytJZz09" className="Reception" target="_blank" rel="noreferrer">
            <div className="Title">CLICK HERE TO WATCH OUR RECEPTION LIVE!</div>
            <div className="Time">WEBCAST STARTS AT 8 PM EST</div>
          </a>
        </div>
      </div>
      <img alt="" className="Img Img-1" src={`${process.env.PUBLIC_URL}/home.png`}/>
      <div className="Div-1">
        {days} Days Away
      </div>
    </Page>
  )
}
export default Home
