import React from 'react'
import Page from './../Page'

function OurStory(props) {
  return (
    <Page title="Our Story" footer>
      <p className="P P1">
        Shubham and Kara met on September 26, 2013 in the Searle Center on Duke University’s campus. She attended the ceremony for the opening of the cancer survivorship center at Duke University Health System. Shubham sat down next to her and introduced himself. Turns out, he was the speaker at this event. He escorted Kara to the reception following the ceremony, and asked her to come to a music night the next night with his colleagues. They got a drink at Alley 26 that night, where he asked her on an “official date” for the next night. He showed up to Kara’s door with flowers right on time and took her to Piedmont, where they shared beet risotto and a Malbec.
      </p>
      <p className="P P1-2">
        They quickly began spending as much free time as possible together. They frequented Mad Hatter’s, Francesca’s, and Bean Trader’s in Durham for coffee and cookies. They learned of a common love for food and restaurants, wine, and bourbon. Shubham introduced Kara to the show “Breaking Bad”, which was the inspiration for their first Halloween costume
      </p>

      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-1" src={`${process.env.PUBLIC_URL}/story-1.png`}/>
      </div>


      <p className="P P2">
        Days became weeks, weeks became months. When it was time for Shubham to move to Kentucky for his first job after training, he convinced Kara to move with him. It was in Kentucky that they got Billy, their adorable puppy child, discovered a love for bourbon, and made many wonderful friends and memories.
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Top Img-2" src={`${process.env.PUBLIC_URL}/story-2-1.png`}/>
        <img className="OurStory-Img Bottom Img-2" src={`${process.env.PUBLIC_URL}/story-2-2.png`}/>
      </div>



      <p className="P P3">
        Kara’s wanderlust took her from the shores of Costa Rica to the hipsters of Portland, Oregon to the desert of Arizona. She eventually found her passion in becoming an oncology nurse practitioner. She moved back home to North Carolina, and worked toward her master’s degree at Duke University. Meanwhile, Shubham was  expanding his practice in Kentucky, working to establish reconstructive and cancer survivorship urology programs at the University of Kentucky.
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-3" src={`${process.env.PUBLIC_URL}/story-3.png`}/>
      </div>


      <p className="P P4">
        During their time apart, they took every opportunity to meet and adventure together, exploring places including swimming with the sea lions off the Galapagos Islands, standing in two hemispheres at once in Ecuador, hiking glaciers in Iceland, statuesque posing at Buckingham Palace in London, celebrating weddings in New Delhi, practicing yoga in Goa, and hiking a volcano in St. Kitts.
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Top Img-4" src={`${process.env.PUBLIC_URL}/story-4-1.png`}/>
      </div>

      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-4" src={`${process.env.PUBLIC_URL}/story-4-2.png`}/>
      </div>


      <p className="P P5">
        The stars aligned, and Kara’s graduation from Duke coincided with Shubham’s acceptance of an awesome career opportunity at University Hospitals. They decided to settle in Cleveland, Ohio.
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-5" src={`${process.env.PUBLIC_URL}/story-5.png`}/>
      </div>


      <p className="P P6">
        As a graduation present, Shubham took Kara to Italy. They explored the rocks of Amalfi Coast, the mountains of Tuscany, the canals of Venice, and the cobblestones of Rome. During their stay in Florence, Shubham brought Kara to the top of Piazza Michaelangelo. It was as the sun was setting against the beautiful Florentine skyline that he got down on one knee and asked her to marry him!
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-6" src={`${process.env.PUBLIC_URL}/story-6.png`}/>
      </div>


      <p className="P P7">
        They both already knew where they wanted to get married. The chapel had been Kara’s dream wedding since she was eleven when her Daddy told her she could be a nurse at Duke, and they have a big chapel. Years later, she attended Mass there as a nurse and graduate student. Her family began a tradition of attending Easter Mass in Cameron Indoor Stadium, where Kara also watched Zion and the blue devils crush their opponents. It seemed only fitting that this was also the very campus she met Shubham.
      </p>
      <p className="P P7-1">
        Duke has very strict rules for being allowed to marry in the chapel. They also have a policy for signing up for a date, and a tradition of campouts on campus. Shubham and Kara knew they would have to camp if they wanted a date.
      </p>
      <p className="P P7-2">
        They spent three days and two nights camping by the chapel, and were able to secure their wedding date! They spent the days taking shifts walking around campus and going back to take showers, eating Shubham’s famous guacamole, and hanging out in lounge chairs with Billy and Kara’s parents.
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-7" src={`${process.env.PUBLIC_URL}/story-7.png`}/>
      </div>


      <p className="P P8">
        Their original plans to marry were quickly turned upside-down when the world shut down just a few days after they sent out their save-the-dates for September 2020. Kara traded her wedding veil in for a face shield, while Shubham spent many days doing virtual consults from home, forced to put down his scalpel for months. Home became their gym, yoga studio, office, restaurant, church, movie theater, place to spend every non-working hour.  In April 2020,  they bought a house in the suburbs, and moved in early summer. They had freedom to roam! Even Billy supported the notion after a few weeks of scrupulous inspection of his new yard.
      </p>
      <p className="P P8-1">
        As they soon realized a postponement of their wedding was inevitable, Shubham and Kara contacted their party and vendors. Thankfully, all of them were available on their new date. Most even went out of their way to ensure they had all the support they needed during their rescheduling process.  Although they do not recommend planning a wedding during a global pandemic, they could not have picked a better team to endure one with, nor could they be more thankful for the support from their friends and family.
      </p>
      <p className="P P8-2">
        Shubham and Kara figured they had not done anything the ordinary way from the day each of them were born, so why start now? They are so excited to celebrate their love and life together with you when the day finally comes!
      </p>
      <div className="ImageDiv">
        <img className="ImageBorder ImageBorder-1" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="ImageBorder ImageBorder-2" src={`${process.env.PUBLIC_URL}/border-2.png`}/>
        <img className="OurStory-Img Img-8" src={`${process.env.PUBLIC_URL}/story-8.png`}/>
      </div>

    </Page>
  )
}
export default OurStory
