import React from 'react'

export default function Person({image, name, title, description}) {
  return (
    <div className="Person">
      <img class="Image" src={image} alt=""/>

      <h3>{title}</h3>
      <img class="Decoration" src={`${process.env.PUBLIC_URL}/decoration3.png`} alt=""/>
      <h4>{name}</h4>

      <p class="Description">{description}</p>
    </div>
  )
}
