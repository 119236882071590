import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Page from './Page';

import Home from './Pages/Home';
import Accomodations from './Pages/Accomodations';
import OurWedding from './Pages/OurWedding';
import OurStory from './Pages/OurStory';
import Registry from './Pages/Registry';
import Updates from './Pages/Updates';
import WeddingParty from './Pages/WeddingParty';
import Weekend from './Pages/Weekend';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/wedding-party" render={(props) => <WeddingParty/> }/>
        <Route path="/our-wedding" render={(props) => <OurWedding/> }/>
        <Route path="/our-story" render={(props) => <OurStory/>}/>
        <Route path="/accomodations" render={(props) => <Accomodations/>}/>
        <Route path="/weekend" render={(props) => <Weekend/>}/>
        <Route path="/registry" render={(props) => <Registry/>}/>
        <Route path="/updates" render={(props) => <Updates/>}/>
        <Route path="/" render={(props) => <Home/> }/>
      </Switch>
    </Router>
  );
}

export default App;
