import React from 'react'
import './Nav.scss';
import {Link} from "react-router-dom";

function Nav(props) {
  return (
    <div className="Nav">
      <img className="NavImg NavImg-1" src={`${process.env.PUBLIC_URL}/nav.png`}/>
      <NavItem text="HOME" to='/' vertical="V2"/>
      <NavItem text="OUR WEDDING" to='/our-wedding'/>
      <NavItem text="MEET THE WEDDING PARTY" to='/wedding-party' vertical="V1"/>
      <NavItem text="OUR STORY" to='/our-story'/>
      <NavItem text="ACCOMO DATIONS" to='/accomodations'/>
      <NavItem text="THE WEEKEND" to='/weekend'/>
      <NavItem text="OUR REGISTRY" to='/registry'/>
      <NavItem text="UPDATES" to='/updates' vertical="V2" notification={1}/>
    </div>
  )
}

function NavItem({text, to, vertical, notification}) {
  return (
    <div className={`NavItem`}>
      <Link className={`NavItem-Text ${vertical}`} to={to}>{text}</Link>
      {notification ? <div className="Notification">{notification}</div> : null}
    </div>
  )
}
export default Nav;
Nav.Item = NavItem;
