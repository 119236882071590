import React from 'react'
import Page from './../Page'

function Accomodations(props) {
  return (
    <Page title="Accomodations" footer>
      <a href='https://reservations.travelclick.com/11999?groupID=3162608' target='_blank'><img className="Accomodations-Img Img-1" src={`${process.env.PUBLIC_URL}/a1.png`}/></a>
      <img className="Accomodations-Img Img-2" src={`${process.env.PUBLIC_URL}/a2.png`}/>
      <a href='https://www.google.com/maps/place/Bryan+Center+Parking+Garage/@36.0016288,-78.9421451,15z/data=!4m5!3m4!1s0x0:0xf93856a810b4dbef!8m2!3d36.0016288!4d-78.9421451' target='_blank'><img className="Accomodations-Img Img-3" src={`${process.env.PUBLIC_URL}/a3.png`}/></a>
      <img className="Accomodations-Img Img-4" src={`${process.env.PUBLIC_URL}/a4.png`}/>
      <img className="Accomodations-Img Img-5" src={`${process.env.PUBLIC_URL}/a5.png`}/>
    </Page>
  )
}
export default Accomodations
