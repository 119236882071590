import React from 'react'
import Page from './../Page'

function Weekend(props) {
  return (
    <Page title="Weekend" footer>
      <img className="Weekend-Img Img-1" src={`${process.env.PUBLIC_URL}/w1.png`}/>
      <img className="Weekend-Img Img-2" src={`${process.env.PUBLIC_URL}/w2.png`}/>
      <img className="Weekend-Img Img-3" src={`${process.env.PUBLIC_URL}/w3.png`}/>

      <div className="Eats">
        <p>Brunch:  Elmo’s Diner • Foster’s Market • Guglhupf • Mad Hatter’s • Nosh • Rue Cler  </p>
        <p>Lunch: Happy + Hale • Nuvo Taco • Toast </p>
        <p>American: B. Good • Local 22 • The Pit • Tobacco Road</p>
        <p>Pizza: Enzo’s • Pie Pushers </p>
        <p>Mexican and South American: Dos Perros •  Gonza Tacos Y Tequila • Luna Rotisserie  </p>
        <p>Asian: Dashi Ramen • JuJu • Itaewon</p>
        <p>Indian Fusion: Viceroy</p>
        <p>Mediterranean: Parizade</p>
        <p>Adult Beverages: Alley Twenty-Six • Bull McCabe’s • Fullsteam Brewery</p>
        <p>Chapel Hill: Bin 54 •  Cholanad • Top of the Hill</p>
        <img className="Eats-Img" src={`${process.env.PUBLIC_URL}/updates-heart.png`}/>
      </div>
    </Page>
  )
}
export default Weekend
